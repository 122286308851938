'use client';

import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import currentChain from 'lib/web3/currentChain';
import { mainnet } from 'viem/chains';
import configs from 'configs/app';

// Get projectId from https://cloud.walletconnect.com
const feature = configs.features.blockchainInteraction;
export const projectId = feature.walletConnect.projectId

if (!projectId) throw new Error('Project ID is not defined')

const metadata = {
  name: process.env.MORPH_WEBSITE_NAME || "Morph Website",
  description:
    process.env.MORPH_WEBSITE_DESCRIPTION ||
    "The Optimisitic zkEVM Scling Solution",
  url: process.env.MORPH_WEBSITE_URL || "https://www.morphl2.io",
  icons: [
    process.env.MORPH_WEBSITE_ICON || 'https://www.morphl2.io/v4/logo/Logo/logo.png',
  ],
};

// Create wagmiConfig
export const config = defaultWagmiConfig({
  chains: [mainnet, currentChain] as const,
  // transports: [mainnet, ...chains].reduce((previousValue, currentValue, currentIndex, arr) => {
  //   previousValue[Number(currentValue.id)] = http(currentValue.rpcUrls.default.http[0]);
  //   console.log('previousValue', previousValue);
  //   return previousValue;
  // }, {}),
  // client({ chain }) { 
  //   return createClient({ chain, transport: http(chain.rpcUrls.default.http[0]) }) 
  // }, 
  projectId,
  metadata,
  // ssr: true,
  // connectors: [],
  // storage: createStorage({
  //   storage: cookieStorage
  // }),
  enableCoinbase: false,
  // enableEmail: false,
  enableWalletConnect: false,
  auth: process.env.NEXT_PUBLIC_MORPH_WALLETCONNECT_SOCIAL_LOGIN ? {
    email: true, // default to true
    socials: ['x', 'discord', 'github', 'facebook', 'google', 'apple'],
    showWallets: true, // default to true
    // walletFeatures: true // default to true
  } : {
    email: false,
    showWallets: true
  }
});
