'use client';

import React, { ReactNode } from 'react'

import { createWeb3Modal } from '@web3modal/wagmi/react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { State, WagmiProvider } from 'wagmi'

import { config, projectId } from './config'
import { getFromLocalStorage, isInLocalStorage } from 'lib/storage'

// Setup queryClient
const queryClient = new QueryClient()

if (!projectId) throw new Error('Project ID is not defined')

const theme = getFromLocalStorage("color-theme") || (!isInLocalStorage("color-theme") ? "light" : "dark") || "light";
// Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  allWallets: 'HIDE',
  themeVariables: {
    "--w3m-accent": "#14A800",
    "--w3m-color-mix": "#fff",
    "--w3m-color-mix-strength": 10,
    "--w3m-border-radius-master": "2px",
  },
  featuredWalletIds: [
    // https://walletconnect.com/explorer/metamask
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", // bitget wallet
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
    "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1", // Rabby
    "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b", // wallectconnect
    // "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef", // imtoken
  ],
  chainImages: {
    2810: 'https://bridge-holesky.morphl2.io/v4/Logo/Morphi-L2-white.svg',
    17000: 'https://bridge-holesky.morphl2.io/images/logo/holesky.svg',
  },
  // excludeWalletIds: [
  //   'walletConnect'
  // ],
  themeMode: theme as any,
  termsConditionsUrl: 'https://www.morphl2.io/termsofservice',
  privacyPolicyUrl: ''
})

export default function Web3ModalProvider({
  children,
  initialState
}: {
  children: ReactNode
  initialState?: State
}) {
  return (
    <WagmiProvider config={config} initialState={initialState}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
